import Uppy from '@uppy/core';
import FileInput from '@uppy/file-input';
import XHRUpload from '@uppy/xhr-upload';
import ProgressBar from '@uppy/progress-bar';

export default () => {
  const fileUrlInput = document.querySelector('.js-uploaded-file-url');
  if (!fileUrlInput) {
    return;
  }
  const uppy = new Uppy({ debug: false, autoProceed: true });
  uppy.on('complete', (result) => {
    if (result.successful && result.successful.length > 0) {
      fileUrlInput.value = result.successful[0].response.body.url;
    }
  });
  uppy.use(FileInput, {
    target: '.js-upload',
    replaceTargetContent: true,
    allowMultipleUploads: false,
    restrictions: {
      maxFileSize: 1024 * 1024,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
    },
  });
  uppy.use(XHRUpload, {
    endpoint: '/api/v1/upload',
    formData: true,
    fieldName: 'file',
  });
  uppy.use(ProgressBar, {
    target: '.js-upload',
    fixed: false,
    hideAfterFinish: false,
  });
};
