import fetch from 'isomorphic-fetch';

const setError = (form) => {
  const error = form.querySelector('.error');
  return (message) => {
    if (error) {
      error.innerText = message;
    }
  };
};

const resetPassword = (form) => (e) => {
  e.preventDefault();
  const csrf = form.querySelector('input[name=_csrf]').value;
  const password1 = form.querySelector('input[name=password1]').value;
  const password2 = form.querySelector('input[name=password2]').value;
  const token = form.querySelector('input[name=token]').value;
  const error = setError(form);
  fetch(form.action, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      _csrf: csrf,
      _method: 'PUT',
      password1,
      password2,
      token,
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        if (result.error) {
          throw new Error(result.error);
        }
        error('');
        form.reset();
        alert('Пароль изменен');
        location.href = '/login';
      }
    })
    .catch((ex) => {
      error(ex.message);
    });
};

const changePassowrd = (form) => (e) => {
  e.preventDefault();
  const csrf = form.querySelector('input[name=_csrf]').value;
  const password1 = form.querySelector('input[name=password1]').value;
  const password2 = form.querySelector('input[name=password2]').value;
  const password = form.querySelector('input[name=password]').value;
  const error = setError(form);
  fetch('/profile/password', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      _csrf: csrf,
      password1,
      password2,
      password,
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        if (result.error) {
          throw new Error(result.error);
        }
        error('');
        form.reset();
        alert('Пароль изменен');
      }
    })
    .catch((ex) => {
      error(ex.message);
    });
};

const init = () => {
  const form = document.querySelector('.js-change-password');
  const resetForm = document.querySelector('.js-reset-password');
  if (form) {
    form.addEventListener('submit', changePassowrd(form));
  }
  if (resetForm) {
    resetForm.addEventListener('submit', resetPassword(resetForm));
  }
};

export default init;
