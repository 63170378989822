// import initPromocodeHandling from './promocode';
import initAuth from './auth';
import initPasswordChange from './password';
import initUpload from './uploader';
import './styles/index.scss';

const onLoad = () => {
  initAuth();
  // initPromocodeHandling();
  initPasswordChange();
  initUpload();

  // toggle mobile menu
  const mobileMenuBtn = document.querySelector('.js-mobile-menu-burger');
  const mobileMenuCloseBtn = document.querySelector('.js-mobile-menu-close');
  let mobileMenuOpen = false;
  let courseMenuOpen = false;

  if (mobileMenuBtn && mobileMenuCloseBtn) {
    const onClickMobileMenu = (e) => {
      e.preventDefault();
      mobileMenuOpen = true;
      const body = document.querySelector('body');
      body.style.overflowY = 'hidden';
      body.style.position = 'fixed';
      document.querySelector('header').classList.add('header--open');
    };

    const onClickMobileMenuClose = (e) => {
      e.preventDefault();
      mobileMenuOpen = false;
      if (!courseMenuOpen) {
        const body = document.querySelector('body');
        body.style.position = 'inherit';
        body.style.overflowY = 'inherit';
      }
      document
        .querySelector('header')
        .classList.remove('header--open', 'header--search');
    };

    mobileMenuBtn.addEventListener('click', onClickMobileMenu);
    mobileMenuCloseBtn.addEventListener('click', onClickMobileMenuClose);
  }

  const paymentSelectorDiv = document.querySelector('.js-payment-selector');
  if (paymentSelectorDiv) {
    const paymentSelectorVariants = paymentSelectorDiv.querySelector(
      '.js-payment-selector__variants'
    );
    const paymentSelectorActions = paymentSelectorDiv.querySelector(
      '.js-payment-selector__actions'
    );

    if (paymentSelectorVariants && paymentSelectorActions) {
      paymentSelectorVariants
        .querySelectorAll('input[type="radio"]')
        .forEach((variant) => {
          variant.addEventListener('change', (e) => {
            const btn = e.target;
            // reset all tabs
            paymentSelectorVariants
              .querySelectorAll('.payment-selector__variant--active')
              .forEach((tab) =>
                tab.classList.remove('payment-selector__variant--active')
              );
            paymentSelectorActions
              .querySelectorAll('[data-tab-id]')
              .forEach((tab) =>
                tab.classList.remove('payment-selector__action--active')
              );
            const tab = paymentSelectorActions.querySelector(
              `[data-tab-id="${btn.dataset.tabId}"]`
            );
            tab.classList.add('payment-selector__action--active');
            btn
              .closest('.payment-selector__variant')
              .classList.add('payment-selector__variant--active');
          });
        });
    }
  }

  const classMobileBtn = document.querySelector('.js-navigation-mobile-btn');
  const classMobileMenu = document.querySelector('.js-navigation-mobile-menu');
  if (classMobileBtn && classMobileMenu) {
    classMobileBtn.addEventListener('click', () => {
      courseMenuOpen = !courseMenuOpen;
      classMobileBtn.classList.toggle('class-navigation__menu--open');
      classMobileMenu.classList.toggle('class-page__navigation--open');
      if (!courseMenuOpen) {
        document.body.style.overflowY = '';
        document.body.style.position = '';
      } else {
        document.body.style.overflowY = 'hidden';
        document.body.style.position = 'relative';
      }
    });
  }

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const copyPromocodeBtn = document.querySelector('.js-copy-promocode');
  if (copyPromocodeBtn) {
    copyPromocodeBtn.addEventListener('click', (event) => {
      try {
        copyToClipboard(event.target.dataset.promocode);
        const initText = event.target.textContent;
        event.target.textContent = event.target.dataset.success;
        setTimeout(() => {
          event.target.textContent = initText;
        }, 2000);
      } catch {}
    });
  }
};

document.addEventListener('DOMContentLoaded', onLoad);
