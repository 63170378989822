import fetch from 'isomorphic-fetch';

const localLogin = (form) => (e) => {
  e.preventDefault();
  const csrf = form.querySelector('input[name=_csrf]').value;
  const email = form.querySelector('input[name=email]').value;
  const password = form.querySelector('input[name=password]').value;
  fetch('/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      _csrf: csrf,
      email,
      password,
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        if (result.error) {
          throw new Error(result.error);
        }
        if (result.message === 'OK') {
          location.href = result.returnUrl || '/';
        }
      }
    })
    .catch((ex) => {
      const error = form.querySelector('.error');
      if (error) {
        error.innerText = ex.message;
      }
    });
};

const localSignUp = (form) => (e) => {
  e.preventDefault();
  const csrf = form.querySelector('input[name=_csrf]').value;
  const email = form.querySelector('input[name=email]').value.trim();
  const password1 = form.querySelector('input[name=password1]').value.trim();
  const password2 = form.querySelector('input[name=password2]').value.trim();
  if (email.length === 0 || password1.length === 0 || password2.length === 0) {
    return;
  }
  fetch('/signup', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      _csrf: csrf,
      email,
      password1,
      password2,
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        if (result.error) {
          throw new Error(result.error);
        }
        if (result.message === 'OK') {
          location.href = result.returnUrl || '/';
        }
      }
    })
    .catch((ex) => {
      const error = form.querySelector('.error');
      if (error) {
        error.innerText = ex.message;
      }
    });
};

const resetPassword = (form) => (e) => {
  e.preventDefault();
  const csrf = form.querySelector('input[name=_csrf]').value;
  const email = form.querySelector('input[name=email]').value.trim();
  if (email.length === 0) {
    return;
  }
  fetch('/password/reset', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      _csrf: csrf,
      email,
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        if (result.error) {
          throw new Error(result.error);
        }
      }
      form.reset();
      alert('На вашу почту отправлены инструкции по смене пароля');
    })
    .catch((ex) => {
      const error = form.querySelector('.error');
      if (error) {
        error.innerText = ex.message;
      }
    });
};

const onAuthTypeSwitch = (hideTarget, showTarget) => (e) => {
  e.preventDefault();
  const hideBlock = document.querySelector(hideTarget);
  const showBlock = document.querySelector(showTarget);
  if (hideBlock && showBlock) {
    hideBlock.style.display = 'none';
    showBlock.style.display = 'block';
  }
};

const onAuthModalClose = (e) => {
  e.preventDefault();
  location.href = '/';
};

const init = () => {
  const loginLink = document.querySelector('.js-login');
  const loginForm = document.querySelector('.js-login-form');
  const resetForm = document.querySelector('.js-reset-form');
  const signupForm = document.querySelector('.js-signup-form');
  const loginSignumSwitchBtns = document.querySelectorAll('.js-auth-switch');
  const authCloseBtn = document.querySelector('.js-auth-close');
  if (loginForm) {
    loginForm.addEventListener('submit', localLogin(loginForm));
  }
  if (signupForm) {
    signupForm.addEventListener('submit', localSignUp(signupForm));
  }
  if (resetForm) {
    resetForm.addEventListener('submit', resetPassword(resetForm));
  }
  if (loginSignumSwitchBtns.length > 0) {
    Array.prototype.forEach.call(loginSignumSwitchBtns, (btn) => {
      btn.addEventListener(
        'click',
        onAuthTypeSwitch(btn.dataset.hideTarget, btn.dataset.showTarget)
      );
    });
  }
  if (authCloseBtn) {
    authCloseBtn.addEventListener('click', onAuthModalClose);
  }
  if (loginLink) {
    loginLink.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector('body').classList.toggle('noscroll');
      document.querySelector('.auth').classList.toggle('auth--open');
    });
  }
};

export default init;
